<template>
  <div class="container" v-loading="loading">
    <el-breadcrumb class="p_tb_10">
      <el-breadcrumb-item
        v-for="(item, index) in breadcrumb"
        :to="item.path"
        :key="index"
        >{{ item.name }}</el-breadcrumb-item
      >
      <el-breadcrumb-item v-show="breadcrumb.length > 0">
        内容详情
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="title">{{ contents.title }}</div>
    <div class="time">
      {{ contents.createTime }}
    </div>
    <div class="contents">
      <div class="media" v-show="contents.url">
        <img
          :src="contents.url"
          width="560px"
          v-if="checkFile(contents.url) == 'img'"
        />
        <video
          v-else
          :src="contents.url"
          controls
          autoplay
          width="560px"
        ></video>
      </div>
      <div class="describe">{{ contents.describes }}</div>
      <div class="content">{{ contents.content }}</div>
    </div>
  </div>
</template>

<script>
import { getDetail,getPartyDetail } from "@/api/party/index";
export default {
  name: "contents",
  data: () => {
    return {
      contents: "",
      breadcrumb: [],
      loading: false,
    };
  },
  created() {
    this.getContent(this.$route.query.from);
    let forms = parseInt(this.$route.query.from);
    switch (forms) {
      case 1:
        this.breadcrumb = [
          {
            name: "党建动态",
            path: "/party/partyTrends?id=" + this.$route.query.id,
          },
        ];
        break;
      case 2:
        this.breadcrumb = [
          {
            name: "党建资源",
            path: "/party/partyResources?id=" + this.$route.query.id,
          },
        ];
        break;
      case 3:
        this.breadcrumb = [
          {
            name: "政治生活",
            path: "/party/politicalLife?id=" + this.$route.query.id,
          },
        ];
        break;
      case 4:
        this.breadcrumb = [
          {
            name: "政治生活",
            path: "/party/politicalLife?id=" + this.$route.query.id,
          },
          {
            name: "三会一课",
            path: "/party/politicalLife/detail?id=" + this.$route.query.id,
          },
        ];
        break;
        case 5:
          this.breadcrumb = [
            {
              name: "学校党建",
              path: "/party/schoolParty",
            },
            {
              name: "党建详情",
              path: "/party/schoolParty/detail?cid="+this.$route.query.id,
            },
          ];
          break;
      case 6:
        this.breadcrumb = [
          {
            name: "党风廉政",
            path: "/party/fineParty?id=" + this.$route.query.id,
          },
        ];
        break;
      case 8:
        this.breadcrumb = [
          {
            name: "学习强国",
            path: "/party/learningPower?id=" + this.$route.query.id,
          },
        ];
        break;
      case 0:
        this.breadcrumb = [
          {
            name: "首页",
            path: "/party/index",
          },
        ];
        break;
      case 9:
        this.breadcrumb = [
          {
            name: "更多",
            path: `/party/indexMore?id=${this.$route.query.id}&type=${this.$route.query.type}`,
          },
        ];
        break;
    }
  },
  methods: {
    //获取内容详情
    async getContent(type) {
      this.loading = true;
      //学校党建内容其他接口获取数据
      if(type==5){
        const { data } = await getPartyDetail({ id: this.$route.query.cid });
        this.contents = data;
      }else{
        const { data } = await getDetail({ id: this.$route.query.cid });
        this.contents = data;
      }


      this.loading = false;
    },
    checkFile(fileUrl) {
      // 取出文件后缀名
      if (fileUrl) {
        let suffixArr = fileUrl.split(".");
        let fileSuffix = suffixArr[suffixArr.length - 1];

        const imgList = ["png", "jpg", "jpeg", "bmp", "gif"];
        if (imgList.indexOf(fileSuffix) > -1) {
          return "img";
        } else {
          console.log(fileSuffix);
        }

        const videoList = [
          "mp4",
          "rmvb",
          "avi",
          "flv",
          "m2v",
          "mkv",
          "wmv",
          "mp3",
          "wav",
        ];
        if (videoList.indexOf(fileSuffix) > -1) {
          return "video";
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: PingFang-SC;
  src: url("../../assets/fonts/苹方黑体-中粗-简.ttf");
}
.container {
  width: 1200px;
  // margin: 20px auto;
  .title {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    line-height: 40px;
    font-size: PingFang-SC;
    padding-top: 10px;
  }
  .time {
    text-align: center;
    padding: 10px;
    font-size: PingFang SC;
    font-size: 15px;
    opacity: 0.5;
  }
  .contents {
    padding-bottom: 20px;
    .content {
      font-size: 15px;
      line-height: 18px;
      font-size: PingFang SC;
      opacity: 0.8;
      word-wrap: break-word;
      white-space: pre-wrap;
      //text-align:center;
    }
    .describe{
      text-align:center;
      font-size:14px;
      line-height:14px;
      margin:10px auto;
    }
    .media {
      text-align: center;
    }
  }
}
</style>
